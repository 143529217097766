<template>
  <b-container class="mb-7">
    <h1>Devices</h1>
    <b-alert v-model="alert.model" variant="danger" dismissible fade>
      {{ alert.text }}
    </b-alert>
    <div class="col-span-12 mt-4">
      <div class="flex flex-wrap xl:flex-nowrap justify-start items-start search-wrap">
        <div class="relative max-w-md mb-2 pr-2 search w-2/3">
          <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <FontAwesomeIcon class="h-5 w-5 text-gray-400 text-xl" :icon="['far', 'magnifying-glass']" />
          </div>
          <input
            v-model="currentSearch"
            class="block w-100 rounded-md border h-11 pl-10"
            type="search"
            @input="debounceInput()"
            placeholder="Enter search"
          />
        </div>
        <LinkButton
          variant="outline"
          v-b-modal="'add-device-modal'"
          class="h-11 mr-4 whitespace-nowrap"
          v-if="isRbAdmin"
        >
          Add Device
        </LinkButton>
        <LinkButton variant="outline" class="h-11 mr-4 whitespace-nowrap" @click.native="syncDevices">
          Sync Devices
        </LinkButton>
        <AddDeviceModal />
      </div>
    </div>

    <DeviceTable :devices="devices" />

    <Pagination :pages="pageCount" :currentPage="currentPage" @updateCurrentPage="updateCurrentPage" class="mt-10" />
  </b-container>
</template>

<script>
import { getAuth, Roles } from '@rafflebox-technologies-inc/auth-service-sdk';

import DeviceServiceV2 from '@/lib/device-service-v2';
import AddDeviceModal from '@/components/modals/AddDeviceModal';
import LinkButton from '@/components/ui/LinkButton';
import DeviceTable from '@/components/ui/DeviceTable';
import Pagination from '@/components/ui/Pagination';
import { debounce } from 'lodash';

export default {
  name: 'Devices',
  components: {
    LinkButton,
    AddDeviceModal,
    DeviceTable,
    Pagination
  },
  data() {
    return {
      alert: {
        text: '',
        model: false
      },
      currentSearch: null,
      isRbAdmin: false
    };
  },
  computed: {
    error() {
      return this.$store.state.devices.error;
    },
    devices() {
      return this.$store.state.devices.devices;
    },
    pageCount() {
      return this.$store.getters['devices/getPageCount'];
    },
    currentPage() {
      return this.$store.state.devices.page;
    },
    search() {
      return this.$store.state.devices.search;
    }
  },
  async mounted() {
    const sessionUser = await getAuth().sessionUser();
    this.$store.commit('devices/SET_ORGANIZATION_ID', sessionUser.organizationUuid);
    this.isRbAdmin = sessionUser.hasRole(Roles.RB_ADMIN);
    this.$store.commit('devices/SET_CURRENT_PAGE', 0);
    this.clearSearch();
    await this.getDevices();
  },
  methods: {
    clearSearch() {
      this.currentSearch = null;
      this.$store.commit('devices/SET_SEARCH', this.currentSearch);
    },
    async getDevices() {
      await this.$store.dispatch('devices/listDevices');
    },
    syncDevices() {
      return DeviceServiceV2.syncDevices()
        .then(() => {
          this.$router.go();
        })
        .catch(() => {
          this.handleError('Failed to sync devices');
        });
    },
    handleError(text) {
      this.alert.text = text;
      this.alert.model = true;
    },
    async updateCurrentPage(page) {
      this.$store.commit('devices/SET_CURRENT_PAGE', page);
      await this.getDevices();
    },
    debounceInput: debounce(async function () {
      this.$store.commit('devices/SET_SEARCH', this.currentSearch);
      this.updateCurrentPage(0);
      await this.getDevices();
    }, 500)
  }
};
</script>

<style scoped>
.container {
  text-align: left;
  margin-left: 115px;
  max-width: 1500px;
}
.search-wrap {
  padding-bottom: 1rem;
}

/* clears the ‘X’ from Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the ‘X’ from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}
</style>
