<template>
  <div class="col-span-12">
    <div class="max-w-full">
      <table class="min-w-full divide-y table-fixed">
        <caption class="sr-only">
          Devices List
        </caption>
        <thead>
          <tr>
            <th scope="col" class="px-3 py-3 text-left text-xs font-bold tracking-wide w-64" v-if="showOrganizations">
              <a href="#" class="group inline-flex text-gray-900 whitespace-nowrap" @click="sort('organizationName')">
                Organization
                <span :class="sortClasses('organizationName')">
                  <FontAwesomeIcon class="h-3" :icon="['far', sortIcon('organizationName')]" />
                </span>
              </a>
            </th>
            <th scope="col" class="px-3 py-3 text-left text-xs font-bold tracking-wide w-40">
              <a href="#" class="group inline-flex text-gray-900 whitespace-nowrap" @click="sort('serialNumber')">
                Serial Number
                <span :class="sortClasses('serialNumber')">
                  <FontAwesomeIcon class="h-3" :icon="['far', sortIcon('serialNumber')]" />
                </span>
              </a>
            </th>
            <th scope="col" class="px-3 py-3 text-left text-xs font-bold tracking-wide w-40">
              <a href="#" class="group inline-flex text-gray-900 no-underline" @click="sort('name')">
                Device Name
                <span :class="sortClasses('name')">
                  <FontAwesomeIcon class="h-3" :icon="['far', sortIcon('name')]" />
                </span>
              </a>
            </th>
            <th scope="col" class="px-3 py-3 text-xs font-bold tracking-wide w-16">
              <p class="group inline-flex text-gray-900">Seller Name</p>
            </th>
            <th scope="col" class="px-3 py-3 text-xs font-bold tracking-wide w-16">
              <p class="group inline-flex text-gray-900">APK Version</p>
            </th>
            <th scope="col" class="px-3 py-3 text-xs font-bold tracking-wide w-16">
              <p class="group inline-flex text-gray-900">Environment</p>
            </th>
            <th scope="col" class="px-3 py-3 text-xs font-bold tracking-wide w-16">
              <p class="group inline-flex text-gray-900">Web Version</p>
            </th>
            <th scope="col" class="px-3 py-3 text-left text-xs font-bold tracking-wide sm:pr-6 w-24">
              <p class="group inline-flex text-gray-900">Status</p>
            </th>
            <th scope="col" class="px-3 py-3 text-left text-xs font-bold tracking-wide sm:pr-6 w-24">&nbsp;</th>
          </tr>
        </thead>
        <tbody class="min-w-full divide-y divide-gray-200 bg-white">
          <tr class="justify-content-center" v-if="loading">
            <td colspan="9" class="p-4">
              <RbLoadingSpinner class="m-auto" />
            </td>
          </tr>

          <tr class="justify-content-center" v-if="!loading && devices.length === 0">
            <td colspan="9" class="p-4 bg-gray-100">
              <div class="flex items-center justify-center m-auto">
                <p class="py-3">No devices added</p>
              </div>
            </td>
          </tr>
          <!-- We don't here v-if as it will be the default if both if are false above -->
          <tr
            v-for="(device, deviceIndex) in devices"
            :key="device.id"
            :class="deviceIndex % 2 === 1 ? undefined : 'bg-gray-100'"
          >
            <td class="px-3 py-4 text-gray-800 max-w-xs" v-if="showOrganizations">
              {{ device.organizationName }}
            </td>
            <td class="px-3 py-4 text-gray-800">{{ device.serialNumber }}</td>

            <td class="px-3 py-4 text-gray-800 max-w-xs">
              <router-link :to="{ path: `device/${device.id}` }" class="p-2 text-gray-800 underline">
                {{ device.name }}
              </router-link>
            </td>
            <td class="px-3 py-4 text-gray-800">
              {{ device.sellerName }}
            </td>
            <td class="px-3 py-4 text-gray-800">
              {{ device.info.apkVersion }}
            </td>
            <td class="px-3 py-4 text-gray-800">
              <a :href="device.info.environmentUrl">
                {{ device.info.environmentUrl }}
              </a>
            </td>
            <td class="px-3 py-4 text-gray-800">
              {{ device.info.webAppCommitHash }}
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-gray-800">
              <StatusBadge :status="device.active" />
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-grey-800">
              <EditDeviceModal @deviceUpdated="deviceUpdated()" v-if="device.id" :deviceId="device.id" class="ml-4" />
              <span class="sr-only">Edit Device</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import StatusBadge from '@/components/ui/StatusBadge';
import EditDeviceModal from '@/components/modals/EditDeviceModal';

export default {
  name: 'DeviceList',
  components: {
    StatusBadge,
    EditDeviceModal
  },
  props: {
    devices: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      organizationId: this.$store.state.devices.organizationId
    };
  },
  computed: {
    loading() {
      return this.$store.state.devices.loading;
    },
    showOrganizations() {
      return !this.organizationId;
    },
    sortBy() {
      return this.$store.state.devices.sortBy;
    },
    sortDir() {
      return this.$store.state.devices.sortDir;
    }
  },
  methods: {
    async sort(column) {
      this.$store.commit('devices/SET_SORT_BY', column);
      this.$store.commit('devices/SET_SORT_DIR', this.sortDir === 'asc' ? 'desc' : 'asc');
      await this.$store.dispatch('devices/listDevices');
    },
    sortIcon(column) {
      if (this.sortBy !== column) return 'chevron-down';
      return this.sortDir === 'desc' ? 'chevron-down' : 'chevron-up';
    },
    sortClasses(column) {
      if (this.sortBy === column) {
        return 'ml-2 flex align-items-center px-1 rounded bg-gray-200 group-hover:bg-gray-300';
      } else {
        return 'invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible';
      }
    },
    async deviceUpdated() {
      await this.$store.dispatch('devices/listDevices');
    }
  }
};
</script>
